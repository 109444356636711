import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PubSub from "pubsub-js";

const Container = styled.section`
  display: ${(props) => (props.open ? "block" : "none")};
  position: fixed;
  z-index: 0;
  border: 0;
  top: 0;
  left: 0;

  iframe {
    background: white;
    position: absolute;
    width: 80%;
    height: 80%;
    transform: translateY(-50%) translatex(-50%);
    left: 50%;
    top: 50%;
    box-shadow: 1px 1px 50px #555;
  }
`;

const ModalIframe = ({ input }) => {
  const [open, setOpen] = useState(false);
  // console.log(open);

  useEffect(() => {
    const token = PubSub.subscribe("MODAL_OPEN", () => {
      setOpen(true);
    });
    document.addEventListener("keydown", _onKey);

    return () => {
      PubSub.unsubscribe(token);
      document.removeEventListener("keydown", _onKey);
    };
  }, []);

  const _onKey = (e) => {
    // console.log(e.keyCode);
    if (e.keyCode === 27) setOpen(false);
  };

  return (
    <Container open={open} className='w-screen h-screen'>
      <div
        className='bg w-full h-full cursor-pointer'
        onClick={() => setOpen(false)}></div>
      <iframe src={open ? input : ""} frameBorder='0' scrolling='no'></iframe>
    </Container>
  );
};

export default ModalIframe;
