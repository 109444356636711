import React, { useState } from "react";
//import { StaticQuery, graphql } from "gatsby"
import { Link, graphql, useStaticQuery } from "gatsby";
import clsx from "clsx";
import styled from "styled-components";
import PubSub from "pubsub-js";
import ModalIframe from "./ModalIframe";

const Nav = styled.nav`
  li a {
    padding: 0 0.3vw;
    padding: 0 0.101em;
    @media all and (max-width: 600px) {
    }
  }
`;

export const query = graphql`
  query {
    nav: allGoogleFeuille1Sheet {
      nodes {
        label
        url
        iframe
      }
    }
  }
`;

const Header = ({ direction }) => {
  const { nav } = useStaticQuery(query);
  const [iframeUrl, setIframeUrl] = useState();

  const _extractNav = () => {
    return nav.nodes.filter((el) => el.label !== "🌀");
  };

  const _extractLinks = () => {
    return nav.nodes.filter((el) => el.label === "🌀");
  };

  const links = _extractLinks();
  const navClean = _extractNav();

  const _getRandomLink = () => {
    const len = links.length - 1;
    const rand = Math.floor(Math.random() * len);
    return links[rand];
  };

  const [randomLink, setRandomLink] = useState(_getRandomLink());

  // const navClean = nav.nodes.filter((el) => el.url != null);

  const _getIframeUrl = () => {
    return navClean.filter((el) => el.url.indexOf("spreadsheets") > -1)[0].url;
  };
  // console.log(_getIframeUrl());
  const _onClickButtonModal = (url) => {
    setIframeUrl(url);
    PubSub.publish("MODAL_OPEN");
  };

  return (
    <header className={clsx("fixed p-md w-full", direction)}>
      <Nav>
        <ul className='flex'>
          {navClean.map((li, i) => (
            <li key={i}>
              {li.iframe === "yup" ? (
                <button onClick={() => _onClickButtonModal(li.url)}>
                  {li.label}
                </button>
              ) : (
                <a href={li.url} target='_blank' rel='noopener, noreferrer'>
                  {li.label}
                </a>
              )}
            </li>
          ))}

          <li>
            <a
              href={randomLink.url}
              target='_blank'
              rel='noopener, noreferrer'
              onClick={() => {
                setRandomLink(_getRandomLink());
              }}>
              {randomLink.label}
            </a>
          </li>
        </ul>
      </Nav>
      <ModalIframe input={iframeUrl} />
    </header>
  );
};

export default Header;
