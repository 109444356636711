import React, { useState, useEffect, createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./Header";
import Footer from "./Footer";
import clsx from "clsx";

const WrapperContext = createContext();
const DarkModeContext = createContext();

const query = graphql`
  query {
    settings: allGoogleFeuille4Sheet {
      nodes {
        metaKey
        metaValue
      }
    }
  }
`;

const Layout = ({ children, pageContext }) => {
  const { settings } = useStaticQuery(query);
  console.log(settings);
  const [direction, setDirection] = useState();
  const [dark, dispatch] = useState(false);

  useEffect(() => {
    _onScroll();
    _format();
    window.addEventListener("scroll", _onScroll);
    window.addEventListener("resize", _format);

    const userPrefersDark =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (userPrefersDark) {
      console.log("User prefers a dark interface");
      dispatch(true);
    }

    // if (window.innerWidth < 1080 && "ontouchstart" in window) setIsMobile(true)

    return () => {
      window.removeEventListener("scroll", _onScroll);
      window.removeEventListener("resize", _format);
    };
  }, []);

  let _prevScrollTop = 0;
  const _onScroll = () => {
    window.pageYOffset > _prevScrollTop
      ? setDirection("down")
      : setDirection("up");

    if (window.pageYOffset === 0) {
      setDirection("");
    }

    _prevScrollTop = window.pageYOffset;
  };
  const _format = () => {
    // console.log("format");
    // const ww = window.innerWidth;
    // const wh = window.innerHeight;
    document.documentElement.style.setProperty(
      "--app-height",
      window.innerHeight + "px"
    );

    const headerBounding = document
      .querySelector("header")
      .getBoundingClientRect();

    document.documentElement.style.setProperty(
      "--header-height",
      headerBounding.height + "px"
    );
  };

  return (
    <WrapperContext.Provider value={{ settings }}>
      <DarkModeContext.Provider value={{ dark, dispatch }}>
        <div id='page' className={clsx(dark ? "is-dark" : "")}>
          <Header direction={direction} />
          <main>{children}</main>
          <Footer />
        </div>
      </DarkModeContext.Provider>
    </WrapperContext.Provider>
  );
};

export { WrapperContext, DarkModeContext, Layout };
