import React, { useContext } from "react";
import { DarkModeContext } from "./Layout";

const Footer = () => {
  const { dark, dispatch } = useContext(DarkModeContext);
  return (
    <footer className='p-md fixed bottom-0 text-right w-full'>
      <button onClick={() => dispatch(!dark)}>🔦</button>
    </footer>
  );
};

export default Footer;
